import "./Promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import flyer from "../../Assets/promo ciputra garden bintaro.webp";
const Promo = () => {
  const waOpen = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=6281353376603&text=Halo+Wely,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan)%20Citra%20Garden%20Bintaro?%20https://www.marketingciputrabintaro.com/&type=phone_number&app_absent=0`,
      "_blank"
    );
  };
  const pointData = {
    poin1: ["Tanpa DP", "Free BPHTB", "Discount up to 3%"],
    poin2: ["Free Canopy", "Free AC", "Voucher Belanja MAP"],
  };
  return (
    <section id="promo" className="section-promo">
      <div className="container">
        <div className="flyer">
          <img src={flyer} alt="promo flyer" />
        </div>
        <div className="highlights">
          <h4>PROMO KESELURUHAN CITRAGARDEN BINTARO</h4>
          <div className="highlights-bottom">
            <div className="msg-hook">
              <div className="container-point">
                {pointData.poin1.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="container-point">
                {pointData.poin2.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="container-btn">
                <div className="term">*T&C apply</div>
                <button onClick={waOpen} className="btn">
                  <FontAwesomeIcon icon={faWhatsapp} className="icon" />
                  Hubungi Kami
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promo;
