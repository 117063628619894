import React from "react";
import "./Facilities.scss";
import clubhouse from "../../Assets/Fasilitas/ft-2.webp";
import joggingtrack from "../../Assets/Fasilitas/ft-3.webp";
import civiccenter from "../../Assets/Fasilitas/ft-4.webp";
import breeze from "../../Assets/Fasilitas/ft-5.webp";
import qbig from "../../Assets/Fasilitas/ft-6.webp";
import eka from "../../Assets/Fasilitas/ft-7.webp";
import shop from "../../Assets/Fasilitas/ft-8.webp";
import hotel from "../../Assets/Fasilitas/ft-9.webp";
import court from "../../Assets/Fasilitas/ft-10.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Facilities = () => {
  const slides = [
    { src: clubhouse, des: "Grand Entrance" },
    { src: joggingtrack, des: "Main Boulevard" },
    { src: civiccenter, des: "Pedestrian Pathway" },
    { src: breeze, des: "Commercial Area" },
    { src: qbig, des: "Club House" },
    { src: eka, des: "Swimming Pool" },
    { src: shop, des: "Kidz Playground" },
    { src: hotel, des: "Tennis & Basketball Court" },
    { src: court, des: "Cluster Gate" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="surroundingcontainer" id="fasilitas">
      <div className="title">FACILITIES</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <div className="imgcontainer">
              <img
                className="imgcontent"
                src={slide.src}
                alt="Surrounding Facilities"
              />
            </div>
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <div className="imgcontainer">
                <img className="imgcontent" src={slide.src} alt="Surrounding" />
              </div>

              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Facilities;
