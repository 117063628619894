import sr from "./fasilitas-1.webp";
import sr2 from "./fasilitas-2.webp";
import sr3 from "./fasilitas-3.webp";
import sr4 from "./fasilitas-4.webp";
import sr5 from "./fasilitas-5.webp";
import sr6 from "./fasilitas-6.webp";
import sr7 from "./fasilitas-7.webp";
import sr8 from "./fasilitas-8.webp";
import sr9 from "./fasilitas-9.webp";

const surroundingImages = [sr, sr2, sr3, sr4, sr5, sr6, sr7, sr8, sr9];

export default surroundingImages;
