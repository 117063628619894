import React from "react";
import "../Pages.scss";
import ScrollToAnchor from "../ScrollToAnchor";
import Hero from "../../Section/Hero/Hero-pages";
import About from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import Product from "../../Section/Product/Product-pages";
import Facilities from "../../Section/Facilities/Facilities";
import Surrounding from "../../Section/Surrounding/Surrounding";

const Home = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Promo />
      <Product />
      <Facilities />
      <Surrounding />
    </div>
  );
};

export default Home;
