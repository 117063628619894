import "./Hero-pages.scss";
import img from "../../Assets/Fasilitas/ft-11.webp";

const HeroPages = () => {
  return (
    <section className="hero-pages">
      <img src={img} alt="Hero" className="hero-pages-img" />
      <div className="container">
        <div className="box">
          <h6 className="caption">Welcome to</h6>
          <h3 className="title">CITRAGARDEN BINTARO</h3>
        </div>
      </div>
    </section>
  );
};

export default HeroPages;
