import "./Product.scss";
import CardProdukPages from "../../Component/Card/CardProdukPages";
import dataProduk from "./data.json";
import {
  russeliaImages,
  raveniaImages,
  dianellaImages,
  calliandraImages,
  celosiaImages,
  magnoliaImages,
} from "../../Assets/Product/images";
const imageMap = {
  russeliaImages,
  raveniaImages,
  dianellaImages,
  calliandraImages,
  celosiaImages,
  magnoliaImages,
};
const ProductPages = () => {
  return (
    <section className="section-product" id="rumah">
      <div className="container-product">
        <h2 className="title">Our Residential</h2>
        <div className="container-card">
          {dataProduk.products.map((data, index) => (
            <CardProdukPages
              key={index}
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              details={data.details}
              link={data.link}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductPages;
