import "./Surrounding.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconHospital } from "../../Assets/svg/hospital.svg";
import surroundingImages from "../../Assets/Surrounding/index";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const pointData = {
    poin1: [
      "5 Menit ke Universitas Pembangunan Jaya",
      "5 Menit ke Stasiun KRL Jurangmangu",
      "10 Menit ke Pasar Modern Bintaro Jaya",
      "10 Menit ke Akses Tol Pondok Ranji",
      "15 Menit ke Rumah Sakit Pondok Indah",
      "30 Menit Pondok Indah Mall",
    ],
  };
  return (
    <section id="surrounding" className="section-surrounding">
      <div className="container-surrounding">
        <h2 className="title">Surrounding Area</h2>
        <div className="container-image">
          <Slider {...settings}>
            {surroundingImages.map((item, index) => (
              <img
                src={item}
                alt="Area Sekitar Citra Garden Bintaro"
                key={index}
                className="image"
              />
            ))}
          </Slider>
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconHospital className="icon-fasilitas" />
              <span>Surrounding Location</span>
            </div>
            {pointData.poin1.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Surrounding;
